import * as React from "react"
import banner_mp4 from "../images/Blokhaus_Banner.mp4"
import banner_webm from "../images/Blokhaus_Banner.webm"
import FullWidthVideo from "../components/Video/FullWidthVideo"
import PrimaryButton from "../components/Buttons/PrimaryButton"
import ArrowButton from "../components/Buttons/ArrowButton"
import Clients from "../components/Clients/Clients"
import ExecutiveCarousel from "../components/Carousel/ExecutiveCarousel"
import Lottie from "../components/Lottie/Lottie"
import { Helmet } from "react-helmet"

import icon1 from "../animations/icon1_75.json";
import icon2 from "../animations/icon2_75.json";
import icon3 from "../animations/icon3_75.json";

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blokhaus</title>
      </Helmet>
      <div className={`bg-black text-white`}>
        <FullWidthVideo 
          webm={banner_webm}
          mp4={banner_mp4}
          className={''}
          poster={'/images/HeroVideoStill.png'}
        />
        <div style={{ maxWidth: 1600 }} className="z-10 relative pt-6 pb-16 px-6 sm:px-12 md:px-24 md:pt-20 md:pb-24 mx-auto">
          <h1 className={`h1-xl mb-6 md:mb-5`}>The Web3<br className="block md:hidden" /> &amp; Blockchain Agency.</h1>
          <h2 className={`p mb-8 md:mb-10`}>Blockchain and Web3 projects move at an extraordinary pace. We'll help you keep up.</h2>
          <PrimaryButton 
            text={`Get in touch`}
            url={`/contact`}
            invert={true}
            onDarkBkg={true}
          />
        </div>
      </div>

      <div className={`text-black`}>
        <div style={{ maxWidth: 1600 }} className="z-10 relative block md:flex md:gap-12 lg:gap-24 pt-10 pb-6 px-6 sm:px-12 md:px-24 md:pt-24 md:pb-20 mx-auto text-center md:text-left">
          <div className={`w-full md:w-6/12 lg:w-3/12 mb-16`}>
            <h2 className={`h2`}>Blokhaus is a marketing and communications agency, moving at the speed of blockchain and Web3.</h2>
          </div>
          <div className={`w-full md:w-6/12 lg:w-9/12`}>
            <IconAndText 
              icon = {icon1}
              endFrame = {50}
              headline = {`We’re blockchain and Web3 natives.`}
              copy = {`As strategists, technologists and creatives from across the industry, we see how blockchain is evolving and how it’s impacting the world around us. We know it, because we love and believe in it.`}
            />
            <IconAndText 
              icon = {icon2}
              endFrame = {25}
              headline = {`We’re focused on the end user.`}
              copy = {`In the ever-changing world of blockchain, there’s one thing that stays consistent: our belief that in order for it to really matter it has to solve a problem for the end user. We are not here to ride a hypewave, we are here to truly connect with users in meaningful and useful ways.`}
            />
            <IconAndText 
              icon = {icon3}
              endFrame = {60}
              headline = {`We’re creative problem solvers.`}
              copy = {`The blockchain category is unique in the myriad of ways you can build campaigns and experiences that bring joy to users. We deliver thoughtful and creative-led campaigns that bring ideas to life in innovative new ways.`}
            />
          </div>
        </div>
      </div>

      <div className={`text-black`}>
        <Clients />
      </div>

      <div className={`text-black`}>
        <div style={{ maxWidth: 1600 }} className="z-10 relative block md:flex md:gap-12 lg:gap-32 pt-10 pb-6 px-6 sm:px-12 md:px-24 md:pt-0 md:pb-20 mx-auto text-left">
          <div className={`w-full md:w-6/12 mb-24 md:mb-16`}>
            <h3 className={`h2 mb-8`}>We are a team of creators, thinkers, doers and blockchain believers focused on impact.</h3>
            <PrimaryButton 
              text={`Explore Careers`}
              url={`/careers`}
              invert={true}
            />
          </div>
          <div className={`hidden md:block border-r border-black`}></div>
          <div className={`w-full md:w-6/12 mb-16`}>
            <h3 className={`h2 mb-8`}>Whether you have a seed of an idea or are curious about the dynamic world of blockchain and Web3, we can help. Let’s build something together.</h3>
            <PrimaryButton 
              text={`Get in touch`}
              url={`/contact`}
              invert={true}
            />
          </div>
        </div>
      </div>

      <div className={`text-black`}>
        <div style={{ maxWidth: 1600 }} className="z-10 relative block md:flex md:gap-5 pt-0 px-6 sm:px-12 md:px-24 mx-auto mb-12 md:mb-11 text-center md:text-left">
          <ExecutiveCarousel />
        </div>
        <div style={{ maxWidth: 1600 }} className="z-10 relative block md:flex md:gap-5 pt-0 px-6 sm:px-12 md:px-24 mx-auto mb-0 md:mb-20 text-center md:text-left">
          <ArrowButton 
            text={'Meet the team'}
            url={'/team'}
            className={''}
          />
        </div>
      </div>
    </>
  )
}

const IconAndText = ({icon, headline, copy, endFrame}) => {
  return <div className={`block lg:flex lg:gap-11 lg:items-start mb-16`}>
            <Lottie file={icon} className='homepage-lottie mb-4' endFrame={endFrame} />
            <div>
              <h3 className={`h2 mb-4 md:mb-2`}>{headline}</h3>
              <p className={'p'}>{copy}</p>
            </div>
          </div>
}

export default IndexPage
