import React, {useEffect, useRef} from "react"
import AutoplayVideo from "./AutoplayVideo";
import * as styles from "./FullWidthVideo.module.scss"

const FullWidthVideo = ({
  mp4,
  webm,
  className,
  poster
}) => {
  const refVideo = useRef(null);

  useEffect(() => {
    if (!refVideo.current) {
        return;
    }

    refVideo.current.defaultMuted = true;
    refVideo.current.muted = true;
}, [refVideo]);

  return (
    <AutoplayVideo mp4={mp4} webm={webm} className={`w-full h-auto ${className}`} poster={poster} />
  )
}

export default FullWidthVideo