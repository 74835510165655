import React, { useEffect, createRef } from "react";
import lottie from "lottie-web";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger)

const Lottie = ({
  file, 
  className = '',
  loop = true,
  autoplay = true,
  endFrame
}) => {
  let animationContainer = createRef();
  let loopCount = 0;

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: loop,
      autoplay: false,
      animationData: file
    });
    anim.pause();

    anim.addEventListener('loopComplete', ()=>{
      if(loopCount === 2){
        anim.removeEventListener('loopComplete')
        anim.addEventListener('enterFrame', (e)=>{
          if(anim.currentFrame >= endFrame){
            anim.pause();
            anim.removeEventListener('enterFrame');
          }
        })
      }

      loopCount++;
    })

    let startAnim = ScrollTrigger.create({
      trigger: animationContainer.current,
      scrub: true,
      start: "bottom bottom",
      onEnter: () => { 
        if(loopCount < 3){
          anim.play();
        }
      }
    });

    return () => anim.destroy();
  }, []);

  return (
    <div className={`w-full mx-auto ${className}`} ref={animationContainer} />
  );
};

export default Lottie;