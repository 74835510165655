import * as React from "react"

const Clients = () => {
  const clients = [
    {
      name : "Evian",
      logo : "evian.jpg",
    },
    {
      name : "Guerlain",
      logo : "guerlain.jpg",
    },
    {
      name : "Oracle Red Bull Racing",
      logo : "oracle_redbull.jpg",
    },
    {
      name : "McLaren Formula 1 Team",
      logo : "mclaren.jpg",
    },
    {
      name : "Team Vitality",
      logo : "vitality.jpg",
    },
    {
      name : "Papa Johns",
      logo : "papajohns.jpg",
    },
    {
      name : "The Mets",
      logo : "mets.jpg",
    },
    {
      name : "Gap",
      logo : "gap.jpg",
    },
    {
      name : "Manchester United",
      logo : "manchester.jpg",
    },
  ]

  return (
    <>
      <div className={`bg-black py-11`}>
        <h4 className={`font-vietnam font-bold text-xl text-white text-center`}>And we’ve done it for<br className={`block md:hidden`} /> these brands</h4>
      </div>
      <div className={`bg-white pt-4 pb-10 mb-16 lg:pb-28 lg:pt-20 lg:mb-32`}>
        <div style={{ maxWidth: 1600 }} className="z-10 relative grid grid-cols-3 gap-4 px-6 sm:px-12 md:px-24 mx-auto xl:flex xl:flex-wrap xl:justify-between">
          {clients.map((client, i)=> {
            return (
              <div className={`${i > 3 ? 'lg:mx-2 xl:mx-8' : 'lg:mx-2 xl:mx-14 xl:mb-4'}`} key={i}>
                <img className={`block mx-auto w-full h-auto`} style={{maxWidth : i > 3 ? '130px' : '200px'}} src={`images/client-logos/${client.logo}`} alt={client.name} key={i} />
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default Clients