import React, {useEffect, useRef} from "react"
import AutoplayVideo from "../Video/AutoplayVideo";

const Headshot = ({
  mp4,
  webm,
  name,
  title,
  poster
}) => {
  const refVideo = useRef(null);

  useEffect(() => {
    if (!refVideo.current) {
        return;
    }

    refVideo.current.defaultMuted = true;
    refVideo.current.muted = true;
}, [refVideo]);

  return (
    <div className={`block text-center`}>
      <AutoplayVideo mp4={mp4} webm={webm} className={`w-full h-auto mb-4`} poster={poster} />
      <h3 className={`text-xs md:text-base font-bold`}>{name}</h3>
      <h4 className={`text-xs md:text-base `}>{title}</h4>
    </div>
    
  )
}

export default Headshot
